import { environment } from "src/environments/environment";

export const API_END_POINTS = {
  AUTH: {
    login: `${environment.APIBaseUrl}/${environment.routePrefix}/auth/login`
  },
  ADMIN: {
    algo_state: `${environment.APIBaseUrl}/${environment.routePrefix}/static`,
    assets: `${environment.APIBaseUrl}/${environment.routePrefix}/asset`,
    parameters: `${environment.APIBaseUrl}/${environment.routePrefix}/user/parameter`,
    selected_assets: `${environment.APIBaseUrl}/${environment.routePrefix}/user/selectedAsset`,
    distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution`,
    insert_distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution/insertValues`,
    validate_distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution/validateSum`,
    search_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/universe/universe/search`,
    green_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/green`,
    red_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/red`,
    green_rational_upload: `${environment.APIBaseUrl}/${environment.routePrefix}/document/upload/green/rational`,
    red_rational_upload: `${environment.APIBaseUrl}/${environment.routePrefix}/document/upload/red/rational`,
    green_rational_delete: `${environment.APIBaseUrl}/${environment.routePrefix}/document/delete/greenUniverse`,
    red_rational_delete: `${environment.APIBaseUrl}/${environment.routePrefix}/document/delete/redUniverse`,
    green_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/download/greenUniverse`,
    red_rational: `${environment.APIBaseUrl}/${environment.routePrefix}/document/download/reduniverse`,
    tail_redemption: `${environment.APIBaseUrl}/${environment.routePrefix}/static`,
    update_tail_redemption: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/tail`,
    presets: `${environment.APIBaseUrl}/${environment.routePrefix}/preset/config`,
    tailconfig: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/rm/byWorkflow`,
    update_tailconfig: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/user/update`,
    customRuleConfig: `${environment.APIBaseUrl}/${environment.routePrefix}/custom/rule/config`
  },
  RM: {
    initiate_session: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/workflow/initiate`,
    onboard: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/onboard/user`,
    user_byPan: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/infoByPan`,
    investments: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/investments`,
    delete_investment: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/investment`,
    rm_distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/distribution`,
    user_distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/portfolio/distribution`,
    portfolio: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/portfolio`,
    user_cass: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/fetchAndLoadCasStatement`,
    current_portfolio_instruments: `${environment.APIBaseUrl}/${environment.routePrefix}/portfolio/client/instruments`,
    current_portfolio_distribution: `${environment.APIBaseUrl}/${environment.routePrefix}/portfolio/client/groupInfo`,
    current_portfolio_allocation: `${environment.APIBaseUrl}/${environment.routePrefix}/portfolio/allocation`,
    update_current_portfolio_instrument: `${environment.APIBaseUrl}/${environment.routePrefix}/portfolio/client/instruments/update`,
    scrip_redemption: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/redemption/control`,
    scrip_consideration: `${environment.APIBaseUrl}/${environment.routePrefix}/portfolio/inConsideration/control`,
    start_process: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/startProcessor`,
    green_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/rm/green`,
    red_universe: `${environment.APIBaseUrl}/${environment.routePrefix}/rm/red`,
    session_history: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow`,
    smart_reblance: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/smartRebalanceValue`,
    reblance_type: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/rebalancing/type/values`,
    smart_comparator: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/calculate/comparator`,
    portfolio_report: `${environment.APIBaseUrl}/${environment.routePrefix}/report/calculate`,
    worflow_search: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/search`,
    final_stat: `${environment.APIBaseUrl}/${environment.routePrefix}/report/investments/stats`,
    final_investment: `${environment.APIBaseUrl}/${environment.routePrefix}/report/v2/investments`,
    add_investment: `${environment.APIBaseUrl}/${environment.routePrefix}/report/add`,
    portfolio_vs_recommened: `${environment.APIBaseUrl}/${environment.routePrefix}/comparator/allocation/portfolio/recommended`,
    user_byWorkflow: `${environment.APIBaseUrl}/${environment.routePrefix}/userProfile/workflow`,
    update_recommended: `${environment.APIBaseUrl}/${environment.routePrefix}/report/update`,
    reset_default: `${environment.APIBaseUrl}/${environment.routePrefix}/report/reset/default`,
    asset_allocation: `${environment.APIBaseUrl}/${environment.routePrefix}/comparator/asset/parent/allocation`,
    delete_recommended: `${environment.APIBaseUrl}/${environment.routePrefix}/report/delete`,
    custom_comparator:  `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/calculate/custom/comparator`,
    custom_minValue: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/calculate/custom/minvalue`,
    ideal_allocation: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/ideal/allocation`,
    preset_minValue: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/calculate/preset/minvalue`,
    review_portfolio_group: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/final/groupInfo`,
    review_portfolio_instruments: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/instruments`,
    review_instrument_update: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/final/update`,
    review_instrument_ignore: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/final/ignore`,
    review_rollback:  `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/final/rollback`,
    portfolio_notes: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/Notes`,
    create_portfolio_notes: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/Notes/create`,
    update_portfolio_notes: `${environment.APIBaseUrl}/${environment.routePrefix}/userPortfolio/Notes/update`,
    sell_instruments_group: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/sell/groupInfo`,
    report_download: `${environment.APIBaseUrl}/${environment.routePrefix}/detail/report/download`,
    add_instruments: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/instrument/add`,
    tail_config_values: `${environment.APIBaseUrl}/${environment.routePrefix}/tailValue/config/rm/byWorkflow`,
    config_prest: `${environment.APIBaseUrl}/${environment.routePrefix}/ConfigCriteria`,
    red_config: `${environment.APIBaseUrl}/${environment.routePrefix}/red/config`,
    green_config: `${environment.APIBaseUrl}/${environment.routePrefix}/green/config`,
    configCriteria: `${environment.APIBaseUrl}/${environment.routePrefix}/ConfigCriteria`,
    config_params: `${environment.APIBaseUrl}/${environment.routePrefix}/Config/parameters`,
    universe_update: `${environment.APIBaseUrl}/${environment.routePrefix}/workflow/update`,
    instrument_peers: `${environment.APIBaseUrl}/${environment.routePrefix}/PublishedUniverseMaster/instruments`,
    update_peers: `${environment.APIBaseUrl}/${environment.routePrefix}/review/projection/recommended/peers/update`
  }
}